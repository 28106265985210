import React, { useEffect, useState } from 'react'
import { useCompaniesContext } from '../../../contexts/Companies';
import BaseButton from "../../../components/common/Button/BaseButton";
import { showSuccessToast } from "../../../libs/helpers/toasts";
import { apiClient } from "../../../libs/api/apiClient";

// @ts-ignore
import InputWrapper from "../../../components/v2/form/input/common/InputWrapper";
import Spoiler from "../../../components/v2/Spoiler";
import TokenInput from '../../../components/v2/form/input/TokenInput';
import ChevronIcon from '../../../components/icons/ChevronIcon';
import SingleSelect, { Option } from '../../../components/v2/form/input/select/SingleSelect';
import { diff } from "../../../libs/helpers/objects";
import Input from '../../../components/v2/form/input/Input';

type RKeeper = {
  clientId: undefined | string,
  secret: undefined | string,
  restaurantId: undefined | string,
  freeSaleId: string | undefined,
  statuses?: {
      '1'?: undefined | string;
      '2'?: undefined | string;
      '3'?: undefined | string;
      '4'?: undefined | string;
      '5'?: undefined | string;
      '6'?: undefined | string;
      '7'?: undefined | string;
      '8'?: undefined | string;
      '9'?: undefined | string;
      '10'?: undefined | string;
      '11'?: undefined | string;
      '12'?: undefined | string;
      '13'?: undefined | string;
  },
}

interface Options {
  restaurantId: Array<Option<string>>;
}

const isDirty = (previous: object, current: object): boolean => {
  return Object
    .keys(current)
    .reduce((changed: boolean, key: string) => {
      if (undefined === previous[key]) {
        return true;
      }

      if (null === current[key] && null === previous[key]) {
        return changed || false;
      }

      if (null !== current[key] && typeof current[key] === 'object') {
        return changed || isDirty(previous[key], current[key]);
      }

      return changed || previous[key] !== current[key]
    }, false);
}

export default function RKeeper({ data, updateField, errors }: any) {
  const { company } = useCompaniesContext()
  const [properties, setProperties] = useState<RKeeper>(data.integrations?.rkeeper || {})
  const [initialProperties, setInitialProperties] = useState<RKeeper>(properties)
  const [options, setOptions] = useState<Options>(data.integrations.rkeeper?.options || {} as Options);
  const [statuses, setStatuses] = useState<Option<string>[]>([]);

  useEffect(() => {
      if (company?.statuses) {
          setStatuses([{label: 'Не выбрано', value: ''}, ...company?.statuses.map((status: any) => ({
              label: `${status.title} (${status.code})`,
              value: status.code
          }))]);
      }
  }, [company?.id])

  const fetchOptions = async (companyId: bigint, branchId: bigint, options: RKeeper): Promise<Options> => {
    try {
        const response = await apiClient.branches.integrations.rkeeper.options(companyId, branchId, { body: { options } });

        if (! response) {
            return {} as Options
        }

        if ("string" === typeof response) {
            return {} as Options
        }

        const { data } = response as any;

        return data;
    } catch (e) {
    }

    return {} as Options;
  }

  const updateIntegration = () => {
    // Костыль. Объект rkeeper обновляется полностью, кроме secret
    const update = { ...properties };
    if (update.secret === initialProperties.secret) {
      delete update.secret;
    }
    updateField(`integrations.rkeeper`, update);
  }

  const updateProperties = (updating: object) => {
    setProperties(options => {
      const state = {...options, ...updating};
      const keys = Object.keys(updating);

      if (! keys.some((key) => ['clientId', 'secret', 'restarauntId'].includes(key))) {
        return state;
      }

      updateOptions(state);

      return state;
    });
  }

  const clear = () => {
    setOptions({} as Options);
    setProperties({} as RKeeper);
  };

  const showOptionsUpdatedToast = () => {
    showSuccessToast({
      content: 'Варианты опций успешно обновлены!'
    });
  }

  const updateOptions = async (state: RKeeper) => {
    if (! (state.clientId || state.secret)) {
        clear()
        return;
    }

    const options = await fetchOptions(company.id, BigInt(data.id), diff<RKeeper>(initialProperties, state));

    if (undefined === options) {
        return;
    }

    if (0 === Object.keys(options).length) {
        return;
    }

    setOptions(options);
    await updateProperties({options});

    showOptionsUpdatedToast()
  };

  useEffect(() => {
    if (! isDirty(data.integrations.rkeeper || {}, properties)) {
      return;
    }

    updateIntegration();
  }, [JSON.stringify(properties)]);

  return (
    <Spoiler title={ "RKeeper" } defaultOpen={true}>
        {/* @ts-ignore */ }
        <Spoiler.Toggle>
          { ({ isOpen, toggleIsOpen, title }) => (
            <div className={ `text-[22px] font-semibold flex items-center justify-between cursor-pointer` }>
              <div onClick={ () => toggleIsOpen() }>{ title }</div>
              <div className={ 'flex items-center gap-4' }>
                <BaseButton
                  onClick={ async () => await updateOptions(properties) }
                  className={ `bg-interactive-elem text-white py-[7px]` }
                >Обновить данные
                </BaseButton>
                <ChevronIcon onClick={ () => toggleIsOpen() } className={ `${ isOpen && 'rotate-180' } duration-300` }/>
              </div>
            </div>
          ) }
        </Spoiler.Toggle>
        <div className={'space-y-8'}>
            <div className={'grid grid-cols-2 gap-4'}>
                <div>
                    <InputWrapper>
                        <InputWrapper.Label>
                            Client ID
                        </InputWrapper.Label>
                        <TokenInput
                            defaultValue={ properties.clientId }
                            name={ 'integrations.rkeeper.clientId' }
                            placeholder={ 'Client ID' }
                            onBlur={ (event) => updateProperties({ clientId: event.target.value }) }
                        />
                    </InputWrapper>
                </div>
                <div>
                    <InputWrapper>
                        <InputWrapper.Label>
                        Client Secret
                        </InputWrapper.Label>
                        <TokenInput
                        defaultValue={ properties.secret }
                        name={ 'integrations.rkeeper.secret' }
                        placeholder={ 'Client Secret' }
                        onBlur={ (event) => updateProperties({ secret: event.target.value }) }
                        />
                    </InputWrapper>
                </div>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <SingleSelect<string>
                value={ properties.restaurantId?.toString() || '' }
                options={ options?.restaurantId || [] }
                label={ 'Ресторан' }
                onChange={ (option) => updateProperties({ restaurantId: option.value }) }
              />
              <div>
                <InputWrapper>
                  <InputWrapper.Label>
                    ID свободной скидки
                  </InputWrapper.Label>
                  <Input
                    name={ 'integrations.rkeeper.freeSaleId' }
                    placeholder={ 'ID свободной скидки' }
                    defaultValue={ data.integrations?.rkeeper?.freeSaleId || '' }
                    onBlur={ (event) => updateProperties({ freeSaleId: event.target.value }) }
                  />
                </InputWrapper>
              </div>
            </div>
            <div className='grid grid-cols-2 gap-4'>
              <SingleSelect<string>
                value={ properties.statuses?.[1] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Новый заказ)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 1: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[2] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Подтвержденный заказ)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 2: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[3] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Принят в работу в ресторане)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 3: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[4] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Передан на кухню)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 4: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[5] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Готовится)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 5: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[6] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Приготовлен)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 6: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[7] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Сборка заказа)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 7: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[8] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Собран)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 8: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[9] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (У курьера)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 9: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[10] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Заказ в пути)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 10: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[11] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Выполнен)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 11: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[12] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Отменен)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 12: option.value } }) }
              />

              <SingleSelect<string>
                value={ properties.statuses?.[13] || '' }
                options={ statuses || [] }
                label={ 'Статус заказа (Доставлен)' }
                onChange={ (option) => updateProperties({ statuses: { ...properties.statuses, 13: option.value } }) }
              />
            </div>


            {/* Инвертируем значение, ибо бек принемает use..., а лейбал: 'Не использовать...' */ }
            <div className='flex flex-col gap-2'>
            <Spoiler title={ 'Подробнее' } size={'sm'} toggleTextColor={'text-interactive-text'}>
                <div className={ "flex flex-col font-medium mt-3 gap-y-2 max-w-[90%]" }>
                <div className='mb-4'>
                    Для того что бы связь работала, необходимо выполнить следующие пункты:
                </div>
                <ul className='mb-4'>
                    <li>
                    1. Включить функцию API на вашем тарифном плане в RKeeper https://docs.rkeeper.ru/delivery/podklyuchenie-po-api-4043842.html
                    </li>
                    <li>
                    2. Скопировать и вставить значение Client ID и Client Secret
                    </li>
                </ul>
                </div>
                <div>
                </div>
            </Spoiler>
            </div>
        </div>
    </Spoiler>
  );
}
