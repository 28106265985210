import React from 'react'
import TextInput from './../../../components/common/form/input/TextInput';
import SingleSimpleValueSelect from '../../../components/common/form/select/SingleSimpleValueSelect';
import TextInputsList from '../../../components/common/form/input/TextInputsList';


import Frontpad from './Frontpad';
import IIKO from './IIKO';
import Partner from './Partner';
import MobiDel from "./MobiDel";
import SBISPresto from "./SBISPresto";
import RKeeper from "../CreateBranch/RKeeper";

export default function Integrations({ data, updateField, errors, updateFieldNoRender }: any) {

  const renderIntegration = () => {
    switch (data?.integrations?.defaultExternalIntegration) {
      case 'frontpad':
        return (
          <Frontpad
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'partner':
        return (
          <Partner
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'iiko':
        return (
          <IIKO
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'mobiDel':
        return (
          <MobiDel
            data={ data }
            updateField={ updateField }
            errors={ errors }
          />
        );
      case 'sbisPresto': {
        return (
          <SBISPresto data={ data } updateField={ updateField } errors={ errors }/>
        )
      }
      case 'rkeeper': {
        return (
          <RKeeper data={ data } updateField={ updateField } errors={ errors }/>
        )
      }
      default:
        return (<></>)
    }
  }

  return (
    <div className={ 'flex flex-col px-3 gap-y-6' }>
      <div className='flex flex-col gap-y-2'>
        <div className='font-bold'>Уведомления об ошибках</div>
        <TextInputsList
          textTooltip='E-mail'
          label='E-mail'
          placeholder='example@exp.com'
          name={ 'notifications.emailsForErrorNotifications' }
          errors={ errors }
          onChange={ (value: any) => updateField('notifications.emailsForErrorNotifications', value) }
          values={ data.notifications.emailsForErrorNotifications || [] }
        />

        <TextInputsList
          textTooltip='VK пользователь'
          label='VK пользователь'
          placeholder='ID'
          name={ 'notifications.vkForErrorNotifications' }
          errors={ errors }
          onChange={ (value: any) => updateField('notifications.vkForErrorNotifications', value) }
          values={ data.notifications.vkForErrorNotifications || [] }
        />

        <div className={ `py-3 px-4 border-gray-20 border-[1px] rounded-lg` }>
          <TextInput
            textTooltip='Telegram ID'
            label='Telegram ID'
            placeholder='@'
            name={ 'notifications.tgForErrorNotifications' }
            errors={ errors }
            onChange={ (value: any) => updateFieldNoRender('notifications.tgForErrorNotifications', value) }
            value={ data.notifications.tgForErrorNotifications }
          />
        </div>
      </div>

      <SingleSimpleValueSelect
        textTooltip='Интеграция с внешней црм'
        label='Интеграция с внешней црм'
        placeholder='Выберите интеграцию'
        name={ 'integrations.defaultExternalIntegration' }
        errors={ errors }
        onChange={ (option: any) => {
          updateField('integrations.defaultExternalIntegration', option)
        } }
        value={ data.integrations?.defaultExternalIntegration }
        options={ [
          { label: 'Frontpad', value: 'frontpad' },
          { label: 'Partner', value: 'partner' },
          { label: 'IIKO', value: 'iiko' },
          { label: 'MobiDel', value: 'mobiDel' },
          { label: 'СБИС Presto', value: 'sbisPresto' },
          { label: 'R_Keeper', value: 'rkeeper' }
        ] }
      />

      { renderIntegration() }

    </div>
  )
}
