import * as React from "react";
import { PropsWithChildren, useState } from "react";
import ChevronIcon from "../icons/ChevronIcon";

type Size = 'md' | 'sm';

type SpoilerProps = PropsWithChildren & {
  title: string,
  defaultOpen?: boolean,
  borderClassName?: string,
  className?: string,
  toggleTextColor?: '' | 'text-interactive-text'
  size?: Size
}

type ToggleProps = {
  toggleIsOpen: () => void
  isOpen: boolean,
  title: string,
}

type DefaultToggleProps = ToggleProps & {
  size?: Size
  toggleTextColor?: '' | 'text-interactive-text'
}

type ToggleWithChildProps = ToggleProps & {
  children: ({ isOpen, toggleIsOpen, title }: ToggleProps) => React.ReactElement
}

const contentMarginSizeClassNames = {
  'sm': '',
  'md': 'mt-8'
}

const contentSizeClassNames = {
  'sm': 'text-sm font-medium',
  'md': ''
}

const toggleSizeClassNames = {
  'sm': 'text-sm font-medium',
  'md': 'text-[22px] font-semibold'
}

const spoilerSizeClassName = {
  'sm': 'px-3 py-2',
  'md': 'p-4'
}

function Toggle({ isOpen, toggleIsOpen, title, size = 'md', toggleTextColor = '' }: DefaultToggleProps) {
  return (
    <div onClick={ () => toggleIsOpen() }
         className={ `${ toggleSizeClassNames[size] } flex items-center justify-between cursor-pointer` }>
      <div className={ `${toggleTextColor}` }>{ title }</div>
      <ChevronIcon className={ `${ isOpen && 'rotate-180' } duration-300` }/>
    </div>
  );
}

export default function Spoiler({
                                  defaultOpen = false,
                                  children,
                                  title,
                                  borderClassName = 'border-gray-20',
                                  className = '',
                                  toggleTextColor = '',
                                  size = 'md'
                                }: SpoilerProps) {
  const [isOpen, setOpen] = useState(defaultOpen);

  let toggle: React.ReactNode | undefined;
  let content: React.ReactNode[] = [];

  const toggleIsOpen = () => setOpen(state => ! state);

  React.Children.forEach(children, (child) => {
    if (! React.isValidElement(child)) {
      return;
    }

    if (child.type === Spoiler.Toggle) {
      toggle = React.cloneElement(child, { title, toggleIsOpen, isOpen } as {})
    } else {
      content.push(child);
    }
  });

  return (
    <div className={ `w-full flex flex-col border-[1px] rounded-lg ${ borderClassName } ${ className } ${spoilerSizeClassName[size]}`  }>
      { (toggle && <>{ toggle }</>) ||
          <Toggle toggleIsOpen={ toggleIsOpen } isOpen={ isOpen } title={ title } size={ size } toggleTextColor={toggleTextColor}/> }
      <div
        className={ `${ isOpen ? `max-h-fit opacity-100 ${contentMarginSizeClassNames[size]}` : 'max-h-0 opacity-0 pointer-events-none !py-0' } ${ contentSizeClassNames[size] } transition-all duration-300 ease-in-out` }>
        { content }
      </div>
    </div>
  );
}

Spoiler.Toggle = function ({ isOpen, toggleIsOpen, title, children }: ToggleWithChildProps) {
  return (<>{ children({ isOpen, toggleIsOpen, title }) }</>)
}