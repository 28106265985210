import React, {useEffect, useState} from "react"
import {useCompaniesContext} from "../../../contexts/Companies";
import {useBranchesContext} from "../../../contexts/Branches";
import {CellContext, ColumnDef, createColumnHelper} from "@tanstack/react-table";
import BaseButton from "../../../components/common/Button/BaseButton";
import Loader from "../../../components/common/Loaders/Loader";
import DataTable from "../../../components/common/Table/DataTable";
import CheckboxList from "../../../components/common/form/checkbox/CheckboxList";
import SingleSimpleValueSelect from "../../../components/common/form/select/SingleSimpleValueSelect";
import {declOfNum} from "../../../libs/helpers/words";
import {showErrorToast, showSuccessToast, showWarningToast} from "../../../libs/helpers/toasts";
import {apiClient} from "../../../libs/api/apiClient";
import ApplyIcon, {Color} from "../../../components/icons/ApplyIcon";
import Checkbox from "../../../components/common/form/checkbox/Checkbox";

type TableProperty = {
    title: string,
    width: number
};

type Row = {
    uid: string,
    price: number,
    article?: string,
    title?: string,

    exists: boolean,
}

const getRenderValue = (property: keyof Row, row: Row) => {
    if (property !== 'exists') {
        return row[property];
    }

    const {exists = false} = row;

    if (exists) {
        return <ApplyIcon color={Color.SUCCESS}/>;
    }

    return false;
}

const renderCell = (property: keyof Row, settings: TableProperty, cell: CellContext<any, any>) => {
    return (
        <div
            style={{width: `${settings.width}%`}}
            className={'font-normal break-all justify-center flex items-center'}>
            {getRenderValue(property, cell.getValue())}
        </div>
    )
}

const CellHeader = ({property, settings, existsCheckBoxToggled}: {property: string, settings: TableProperty, existsCheckBoxToggled: (checked: boolean) => void}) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        existsCheckBoxToggled(checked)
    }, [checked]);

    if ('exists' !== property) {
        return (<>{settings.title}</>);
    }

    return (
      <>
          <Checkbox
            label={settings.title}
            name={'generate'}
            onChange={(checked: boolean) => setChecked(checked)}
            value={checked}
          />
      </>
    );
}


export const RKeeperImport = () => {
    const {company} = useCompaniesContext()
    const {branch} = useBranchesContext()
    const [attributes, setAttributes] = useState<Array<string>>([])
    const [categories, setCategories] = useState<any>([])
    const [externalCategories, setExternalCategories] = useState<Array<{ id: string, title: string }>>([])
    const [selectedExternalCategory, setSelectedExternalCategory] = useState<string>('');
    const [selectCategory, setSelectCategory] = useState('')
    const [goods, setGoods] = useState<any[]>([])
    const [, setGoodsErrors] = useState<any>(null)
    const [errors, setErrors] = useState<any>(null)
    const [selectedRows, setSelectedRows] = useState<any>([])
    const [loading, setLoading] = useState<any>(false)
    const [batchId, setBatchId] = useState<any>(null)
    const [batchInfo, setBatchInfo] = useState<any>(null)
    const [loadingImport, setLoadingImport] = useState<boolean>(false)
    const [columns, setColumns] = useState<Array<ColumnDef<any, any>>>([]);
    const [isColumnsSet, setIsColumnsSet] = useState(false);
    const [importableAttributes, setImportableAttributes] = useState<Array<{ label: string; name: string }>>([])
    const [isImportableAttributesSet, setIsImportableAttributesSet] = useState(false)
    const [isSelectExistsCheckboxChecked, setSelectExistsCheckboxChecked] = useState(false);
    const columnHelper = createColumnHelper<any>()

    const fetchImportable = () => {
        setLoading(true)

        apiClient.import.rkeeper.goods.get(company.id, branch.id, {query: {selectedExternalCategory}})
            .then(({data}: any) => {
                if (!data) {
                    return;
                }

                const {
                    goods,
                    categories,
                    externalCategories,
                    errors,
                    goods_errors,
                    importableAttributes,
                    tableProperties
                } = data

                if (goods && goods instanceof Array) {
                    if (goods?.length == 0) {
                        showWarningToast({
                            content: 'Не найдены товары из R_Keeper!'
                        });
                        setGoods([]);
                    } else {
                        setGoods(goods)
                    }
                }

                buildColumns(tableProperties)
                buildImportableAttributes(importableAttributes)

                setExternalCategories(externalCategories);
                setGoodsErrors(goods_errors)
                setErrors(errors)
                setCategories(categories)

            }).catch((reason: any) => {
            showErrorToast({
                content: reason || 'Error'
            })
        }).finally(() => setLoading(false))
    }

    const handleExistsCheckboxToggled = (checked: boolean) => {
        setSelectExistsCheckboxChecked(checked);
    }

    const buildColumns = (tableProperties: { [s in keyof Row]: TableProperty; }) => {
        /* Второй и более раз нам незачем колонки менять */
        if (isColumnsSet) {
            return;
        }

        setColumns([]);

        Object.entries<TableProperty>(tableProperties).forEach(
            ([property, settings]: [keyof Row, TableProperty]) => {
                setColumns((prevState) => [...prevState, columnHelper.accessor(row => row, {
                    id: property,
                    header: () => <CellHeader property={property} settings={settings} existsCheckBoxToggled={handleExistsCheckboxToggled} />,
                    cell: cell => renderCell(property, settings, cell),
                    enableSorting: false,
                    meta: {
                        widthClass: 'justify-center',
                        style: {width: `${settings.width}%`}
                    },
                    size: settings.width
                })])
            }
        )

        setIsColumnsSet(true);
    }

    const buildImportableAttributes = (importableAttributes: { [key: string]: string }) => {
        if (isImportableAttributesSet) {
            return;
        }

        setImportableAttributes([]);

        Object.entries(importableAttributes).forEach(
            ([name, label]) => {
                setImportableAttributes((prevState) => [...prevState, {
                    label,
                    name
                }])
            }
        )

        setIsImportableAttributesSet(true);
    }

    const handleSubmit = () => {
        if (company && branch) {
            setErrors(null)

            apiClient.import.rkeeper.goods.start(company.id, branch.id, {
                body: {
                    importable: selectedRows,
                    categoryId: selectCategory,
                    attributes
                }
            }).then(({data, errors, message}: any) => {
                if (errors || message) {
                    showErrorToast({
                        content: message || "Произошла ошибка при выполнении импорта"
                    })
                } else if (data) {
                    setLoadingImport(true)
                    setBatchId(data?.batchId)
                    return
                }
            }).catch((reason) => {
                showErrorToast({
                    content: reason || "Произошла ошибка при выполнении импорта"
                })
            })
        }
    }

    const refreshGoods = () => {
        apiClient.import.rkeeper.goods.refresh(company.id, branch.id).then(({message}: any) => {
            if (message) {
                showErrorToast({
                    content: message || 'Произошла непредвиденная ошибка!'
                })
            } else {
                showSuccessToast({
                    content: 'Запрос на обновление успешно отправлен'
                })
            }
        }).catch((reason: any) => {
            showErrorToast({
                content: reason || 'Произошла непредвиденная ошибка!'
            })
        })
    }

    const checkImportInfo = () => {
        setLoadingImport(true)
        apiClient.import.goods.checkProgress(company.id, branch.id, batchId).then(({data}: any) => {
            if (data.done ||
                data?.finishedAt ||
                data?.progress == 100) {
                setLoadingImport(false)
                setBatchId(null)
                showSuccessToast({
                    content: 'Импорт завершен!'
                })
            }
            setBatchInfo(data)

            if ('errors' in data && data.errors instanceof Object && Object.keys(data.errors).length > 0) {
                setErrors(data?.errors)
            }

        }).catch((reason: any) => {
            console.log(reason)
        })
    }

    useEffect(() => {
        setSelectedRows([])
        setSelectCategory('')
        setErrors(null)
    }, [goods])

    useEffect(() => {
        if (batchId) {
            const interval = setInterval(checkImportInfo, 3000)

            return () => clearInterval(interval)
        }

        return
    }, [batchId])

    useEffect(() => {
        if (company && branch) {
            fetchImportable();
        }
    }, [company, branch, selectedExternalCategory]);

    useEffect(() => {
        if (isSelectExistsCheckboxChecked) {
            setSelectedRows(goods.filter((good) => good.exists).map((good) => good.uid));

            return;
        }

        setSelectedRows([]);
    }, [goods, isSelectExistsCheckboxChecked]);

    const renderInfo = () => {
        return (
            <div className={`flex flex-col gap-y-2`}>
                {
                    loadingImport && <Loader title={'Выполняется импорт, пожалуйста подождите...'}/>
                }
                <div>Всего записей для импорта: {batchInfo?.totalJobs}</div>
                <div>Обработано записей: {batchInfo?.processedJobs}</div>
                <div>Пропущено записей: {batchInfo?.skipped}</div>
                <div>Количество ошибок: {batchInfo?.failed}</div>
                {
                    batchInfo?.failed > 0 && errors && Object.keys(errors).length > 0 &&
                    <div className={`text-xs pl-4`}>
                        {
                            Object.entries(errors).map(([key, value]: any) => {
                                const goodIndex = goods.findIndex((good: any) => good?.uid == key)

                                if (goodIndex < 0) return <></>

                                return (
                                    <div className={`flex flex-col gap-y-2`}>
                                        {
                                            value && value instanceof Array && value.map((message: any) => (
                                                <div className={'text-error-main'}>Ошибка в
                                                    строке {goodIndex + 1}: {message}</div>
                                            ))
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <div>Обновлено записей: {batchInfo?.updated}</div>
                <div>Добавлено записей: {batchInfo?.added}</div>
            </div>
        )
    }

    return (
        <div className="px-8 pb-8 pt-2">
            <div className="flex flex-col">
                <div className="flex justify-between items-end">
                    <div className="w-full">
                        <div className="text-gray-50 text-[44px] leading-[60px] mb-2">Каталог</div>
                        <div className={`flex flex-row justify-between items-center`}>
                            <div className="text-gray-40 text-base">Импорт из RKeeper</div>
                            <div>
                                <BaseButton
                                    className={`ml-5 py-[10px] px-[14px] h-fit bg-interactive-elem text-white`}
                                    onClick={() => {
                                        refreshGoods()
                                    }}>
                                    Обновить данные
                                </BaseButton>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={'mt-8 bg-white p-4 rounded-2xl flex flex-col gap-y-4'}>
                {
                    batchInfo && renderInfo()
                }
                {!!externalCategories.length &&
                    <div className="ml-5 flex flex-row items-center">
                        <div className="text-gray-40 text-base font-bold mr-3">Категория</div>
                        <SingleSimpleValueSelect
                            name={'externalCategory'}
                            placeholder={'Выберите категорию'}
                            value={selectedExternalCategory}
                            options={[{label: 'Все', value: ''}, ...externalCategories?.map(({
                                                                                                 id,
                                                                                                 title
                                                                                             }) => ({
                                label: title,
                                value: id
                            }))]}
                            onChange={(value: string) => setSelectedExternalCategory(value)}
                        />
                    </div>
                }
                {
                    loading ? <Loader title={'Идет загрузка данных...'}/> : <DataTable
                        classNameTable={'border-[1px] border-gray-20 rounded-2xl p-3 mb-[20px] !pt-0'}
                        data={goods || []}
                        columns={columns}
                        usePagination={false}
                        useMassAction={true}
                        selectedRows={selectedRows}
                        onChangeSelectionRows={(values: any) => setSelectedRows(values)}
                        updateData={() => {
                        }}
                        rowIdentifierField={'uid'}
                        fixedHeader={'sticky top-0 bg-white w-full'}
                        rowsWithCustomBg={goods.filter((good: any) => errors && Object.keys(errors).includes(good.uid)).map(({uid}: any) => ({
                            id: uid,
                            color: 'bg-error-main/[.5]'
                        }))}
                    />
                }
                {
                    selectedRows.length > 0 &&
                    <div className={'flex flex-col gap-y-4'}>
                        <div className={'border-[1px] border-gray-20 p-3 rounded-2xl'}>
                            <CheckboxList
                                values={attributes || []}
                                options={importableAttributes}
                                classNameWrapper={'flex-row gap-y-4 flex-wrap'}
                                classNameCheckbox={'w-2/12'}
                                onChange={(values: Array<string>) => setAttributes(values)}
                                name={'fields'}
                                showLabel={false}
                                label={undefined}
                            />
                        </div>
                        <div className={'flex flex-row justify-between items-end'}>
                            <div className={'w-[60%]'}>
                                <SingleSimpleValueSelect
                                    label={'Раздел'}
                                    name={'category'}
                                    options={categories.map(({id, title}: any) => ({label: title, value: id}))}
                                    onChange={(value: any) => {
                                        setSelectCategory(value)
                                    }}
                                    value={selectCategory}
                                    errors={{}}
                                    textTooltip={'Раздел'}
                                />
                            </div>
                            <BaseButton disabled={loadingImport}
                                        className={`py-[10px] px-[14px] h-fit bg-interactive-elem text-white border-[1px] border-interactive-elem ${loadingImport && 'bg-interactive-text w-[200px]'}`}
                                        onClick={() => {
                                            handleSubmit()
                                        }}>{loadingImport ? <Loader title={''} className={''}
                                                                    SVGClassName={'!text-white'}/> : `Сохранить ${selectedRows.length} ${declOfNum(selectedRows.length, ['товар', 'товара', 'товаров'])}`}</BaseButton>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
