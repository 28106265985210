import {MakeContext} from "./common";
import {useCompaniesContext} from "./Companies";
import {useBranchesContext} from "./Branches";
import {apiClient} from "../libs/api/apiClient";

const [CompaniesTagsContextWrapper, useCompaniesTagsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.companiesTags.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 0
    }
  },
  makeValue: (companiesTags: any, updateCompaniesTags: any, updateFilters: any) => ({
    companiesTags, updateCompaniesTags, updateFilters
  })
})

export {CompaniesTagsContextWrapper, useCompaniesTagsContext}

const [CustomersTagsContextWrapper, useCustomersTagsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.customersTags.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 0
    }
  },
  makeValue: (customersTags: any, updateCustomersTags: any, updateFilters: any) => ({
    customersTags, updateCustomersTags, updateFilters
  })
})

export {CustomersTagsContextWrapper, useCustomersTagsContext}

const [OrdersTagsContextWrapper, useOrdersTagsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.ordersTags.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 0
    }
  },
  makeValue: (ordersTags: any, updateOrdersTags: any, updateFilters: any) => ({
    ordersTags, updateOrdersTags, updateFilters
  })
})

export {OrdersTagsContextWrapper, useOrdersTagsContext}

const [PromoTagsContextWrapper, usePromoTagsContext] = MakeContext({
  useEverything: () => {
    const {company}: any = useCompaniesContext()
    const {branch}: any = useBranchesContext()

    return {
      isReady: () => (company && branch),
      fetchAll: (queryParams: any) => apiClient.promoTags.getAll(company.id, branch.id, queryParams),
      updateDeps: [company?.id, branch?.id],
      startLimit: 0
    }
  },
  makeValue: (promoTags: any, updatePromoTags: any, updateFilters: any) => ({
    promoTags, updatePromoTags, updateFilters
  })
})

export {PromoTagsContextWrapper, usePromoTagsContext}
