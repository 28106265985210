import * as React from "react";
import { useEffect } from "react";
import { createBrowserRouter, Navigate, redirect, RouterProvider } from "react-router-dom";
import useUser from "./hooks/useUser";
import Primary from "./layouts/Primary";

import Login from "./pages/Login";
import Companies from "./pages/Companies";
import CreateCompany from "./pages/Companies/CreateCompany";

import Branches from "./pages/Branches";
import CreateBranch from "./pages/Branches/CreateBranch";
import EditBranch from "./pages/Branches/EditBranch";
import DeliveryZones from "./pages/DeliveryZones";
import CreateDeliveryZones from "./pages/DeliveryZones/CreateDeliveryZones";
import EditDeliveryZones from "./pages/DeliveryZones/EditDeliveryZone";
import Categories from "./pages/Categories";
import CreateCategory from "./pages/Categories/CreateCategory";
import EditCategory from "./pages/Categories/EditCategory";
import Products from "./pages/Products";
import CreateProduct from "./pages/Products/CreateProduct";
import Options from "./pages/Options";
import CreateOption from "./pages/Options/Options/CreateOption";
import EditOption from "./pages/Options/Options/EditOption";
import CreateOptionsGroups from "./pages/Options/OptionsGroup/CreateOptionsGroup";
import EditOptionsGroup from "./pages/Options/OptionsGroup/EditOptionsGroup";
import CreateSkuGroup from "./pages/Options/SkuGroups/CreateSkuGroup";
import EditSkuGroup from "./pages/Options/SkuGroups/EditSkuGroup";
import Orders from "./pages/Orders";
import CreateOrders from "./pages/Orders/CreateOrders";
import EditOrders from "./pages/Orders/EditOrders";
import AllClients from "./pages/Clients/AllClients";
import VkClients from "./pages/Clients/VkClients";
import CreateClient from "./pages/Clients/CreateClient";
import EditClient from "./pages/Clients/EditClient";
import Promo from "./pages/Promo";
import { PromoContextWrapper } from "./contexts/Promo";
import CreatePromo from "./pages/Promo/CreatePromo";
import EditPromo from "./pages/Promo/EditPromo";
import Reviews from "./pages/Reviews";
import EditReview from "./pages/Reviews/EditReview";
import Nps from "./pages/Nps";
import CreateQuestions from "./pages/Nps/Questions/CreateQuestions";
import EditQuestions from "./pages/Nps/Questions/EditQuestions";
import FoodRating from "./pages/FoodRating";
import Tags from "./pages/Tags";
import CreateCustomersTag from "./pages/Tags/Customers/CreateCustomersTag";
import EditCustomersTag from "./pages/Tags/Customers/EditCustomersTag";
import CreateOrdersTag from "./pages/Tags/Orders/CreateOrdersTag";
import EditOrdersTag from "./pages/Tags/Orders/EditOrdersTag";
//import CreateCompaniesTag from "./pages/Tags/Companies/CreateComapniesTag";
//import EditCompaniesTag from "./pages/Tags/Companies/EditComapniesTag";
import CreatePromoTag from "./pages/Tags/Promo/CreatePromoTag";
import EditPromoTag from "./pages/Tags/Promo/EditPromoTag";
import ReportProducts from "./pages/ReportProducts";
import ReportPromoCodes from "./pages/ReportPromoCodes";
import ReportPromoCode from "./pages/ReportPromoCode";
import ReportTransaction from "./pages/ReportTransaction";
import ReportOrders from "./pages/ReportOrders";
import { SkuGroupContextWrapper } from "./contexts/Options";

import './styles/globals.sass';
import EditCompany from "./pages/Companies/EditCompany";
import CreateEmployee from "./pages/Employees/CreateEmployee";
import Employees from "./pages/Employees";
import EditProduct from "./pages/Products/EditProduct";

/*Стили для тостов*/
import 'react-toastify/dist/ReactToastify.css';

/* Регистрация */
import Register from "./pages/Register";
import VerifyEmail from "./pages/Register/Verify/Email";
import EditEmployee from "./pages/Employees/EditEmployee";
import Dashboard from "./pages/Dashboard";
import { auth } from "./libs/auth";
import {
  checkBranchAction,
  checkCompanyAction,
  checkCompanyCreate,
  checkSectionAction
} from "./libs/helpers/permissions";
import Stocks from "./pages/Stocks";
import CreateStock from "./pages/Stocks/CreateStock";
import { DeliveryZonesContextWrapper } from "./contexts/DeliveryZones";
import { CustomersTagsContextWrapper, OrdersTagsContextWrapper, PromoTagsContextWrapper } from "./contexts/Tags";

import OutputOrders from "./pages/Orders/OutputOrders";
import LoyaltyPrograms from "./pages/LoyaltyPrograms";
import CreateLoyaltyRule from "./pages/LoyaltyPrograms/Rules/CreateRule";
import CreateLoyaltyStatus from "./pages/LoyaltyPrograms/Statuses/CreateStatus";
import CreateLoyaltyTransaction from "./pages/LoyaltyPrograms/Transactions/CreateTransaction";
import EditStock from "./pages/Stocks/EditStock";
import { ClientsContextWrapper } from "./contexts/Clients";
import { OrdersContextWrapper } from "./contexts/Orders";
import { ReportOrdersContextWrapper, ReportProductsContextWrapper } from "./contexts/Reports";
import Histories from "./pages/Histories";
import CreateHistory from "./pages/Histories/CreateHistory";
import EditHistory from "./pages/Histories/EditHistory";
import { CategoriesContextWrapper } from "./contexts/Categories";
import { ProductsContextWrapper } from "./contexts/Products";
import { EmployeesContextWrapper } from "./contexts/Employees";
import Mailings from "./pages/Mailings";
import { MailingsArchiveContextWrapper, MailingsContextWrapper } from "./contexts/Mailings";
import CreateMailing from "./pages/Mailings/CreateMailing";
import EditMailing from "./pages/Mailings/EditMailing";
import { showErrorToast } from "./libs/helpers/toasts";
import EditStatus from "./pages/LoyaltyPrograms/Statuses/EditStatus";
import EditRules from "./pages/LoyaltyPrograms/Rules/EditRules";
import { NpsGoodReviewContextWrapper, NpsReviewContextWrapper } from "./contexts/NPS";
import AdminAuth from './pages/AdminAuth'
import Funnels from "./pages/Funnels";
import CreateFunnelGroup from "./pages/Funnels/CreateFunnelGroup";
import EditFunnelGroup from "./pages/Funnels/EditFunnelGroup";
import ProductFileImport from "./pages/Import/File";
import CreateFunnel from "./pages/Funnels/CreateFunnel";
import { FunnelsContextWrapper } from "./contexts/Funnels";
import { Personal } from "./pages/Personal";
import { FrontpadImport } from "./pages/Import/Frontpad";
import { PartnerImport } from "./pages/Import/Partner";
import { VkClientsEventsContextWrapper } from "./contexts/VkClientsEvents";
import ProductVkImport from "./pages/Import/Vk";
import { IIKOImport } from "./pages/Import/Iiko";
import { MobiDelImport } from "./pages/Import/mobiDel";
import { SbisPrestoImport } from "./pages/Import/SbisPresto";
import { RKeeperImport } from "./pages/Import/RKeeper";

const routerAuth = createBrowserRouter([
  {
    path: "/admin-auth/:token",
    element: <AdminAuth/>,
  },
  {
    path: "/",
    element: <Primary/>,
    loader: () => {
      auth.update().then().catch()
      return null
    },
    children: [
      {
        path: "/",
        element: <Dashboard/>,
      },
      {
        path: "/companies",
        element: <Companies/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          return null
        }
      },
      {
        path: "/companies/create",
        element: <CreateCompany/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          if (!checkCompanyCreate()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/companies')
          }
          return null;
        }
      },
      {
        path: "/companies/:id/edit",
        element: <EditCompany/>,
        loader: ({params}) => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          if (!checkCompanyAction('update', params.id)) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/companies')
          }
          return null;
        }
      },
      {
        path: "/branches",
        element: <Branches/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          return null
        }
      },
      {
        path: "/branches/create",
        element: <CreateBranch/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }

          if (!checkBranchAction('create') ) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/branches')
          }
          return null
        }
      },
      {
        path: "/branches/:id/edit",
        element: <EditBranch/>,
        loader: ({params}) => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          if (!checkBranchAction('update', '([0-9])+', params.id)) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/branches')
          }
          return null
        }
      },
      {
        path: "/delivery-zones",
        element: <DeliveryZonesContextWrapper> <DeliveryZones/> </DeliveryZonesContextWrapper>,
      },
      {
        path: "/delivery-zones/create",
        element: <CreateDeliveryZones/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }

          if (!checkBranchAction('update', localStorage.getItem('companyId'))) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/delivery-zones')
          }
          return null
        }
      },
      {
        path: "/delivery-zones/:id/edit",
        element: <EditDeliveryZones/>,
        loader: ({params}) => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          if (!checkBranchAction('update') && !checkCompanyAction()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/delivery-zones')
          }
          return null
        }
      },
      {
        path: "/employees",
        element: <EmployeesContextWrapper><Employees/></EmployeesContextWrapper>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          if (!checkCompanyCreate()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/companies')
          }
          return null;
        }
      },
      {
        path: "/employees/create",
        element: <CreateEmployee/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          if (!checkCompanyCreate()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/companies')
          }
          return null;
        }
      },
      {
        path: "/employees/:id/edit",
        element: <EditEmployee/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          if (!checkCompanyCreate()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/companies')
          }
          return null;
        }
      },
      {
        path: "/categories",
        element: <CategoriesContextWrapper><Categories/></CategoriesContextWrapper>,
      },
      {
        path: "/categories/create",
        element: <CreateCategory/>,
      },
      {
        path: "/categories/:id/edit",
        element: <EditCategory/>,
      },
      {
        path: "/products",
        element: <ProductsContextWrapper>
          <CategoriesContextWrapper>
            <SkuGroupContextWrapper>
              <Products/>
            </SkuGroupContextWrapper>
          </CategoriesContextWrapper>
        </ProductsContextWrapper>
      },
      {
        path: "/products/create",
        element: <CreateProduct/>
      },
      {
        path: "/products/:id/edit",
        element: <EditProduct/>
      },
      {
        path: "/options",
        element: <Options/>
      },
      {
        path: "/options/create-option",
        element: <CreateOption/>
      },
      {
        path: "/options/:id/edit-option",
        element: <EditOption/>
      },
      {
        path: "/options/create-options-group",
        element: <CreateOptionsGroups/>
      },
      {
        path: "/options/:id/edit-options-group",
        element: <EditOptionsGroup/>
      },
      {
        path: "/options/create-sku-group",
        element: <CreateSkuGroup/>
      },
      {
        path: "/options/:id/edit-sku-group",
        element: <EditSkuGroup/>
      },
      {
        path: "/orders",
        element:
        <OrdersContextWrapper>
          <Orders/>
        </OrdersContextWrapper>,
      },
      {
        path: "/orders/create",
        element: <CreateOrders/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }

          if (!checkSectionAction('orders',  '\\*', localStorage.getItem('companyId'), localStorage.getItem(`companies.${ localStorage.getItem('companyId') }.branch`)) && !checkCompanyCreate()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/orders')
          }
          return null
        }
      },
      {
        path: "/orders/:id",
        element: <ProductsContextWrapper><OutputOrders/></ProductsContextWrapper>
      },
      {
        path: "/orders/:id/edit",
        element: <EditOrders/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }

          if (!checkSectionAction('orders', '\\*', localStorage.getItem('companyId'), localStorage.getItem(`companies.${ localStorage.getItem('companyId') }.branch`)) && !checkCompanyCreate()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/orders')
          }
          return null
        }
      },
      {
        path: "/histories",
        element: <Histories/>
      },
      {
        path: "/histories/create",
        element: <CustomersTagsContextWrapper><CreateHistory/></CustomersTagsContextWrapper>
      },
      {
        path: "/histories/:id/edit",
        element: <CustomersTagsContextWrapper><EditHistory/></CustomersTagsContextWrapper>
      },
      {
        path: "/clients",
        element:
          <ClientsContextWrapper>
            <CustomersTagsContextWrapper>
              <AllClients/>
            </CustomersTagsContextWrapper>
          </ClientsContextWrapper>
      },
      {
        path: "/vk-clients",
        element: 
          <VkClientsEventsContextWrapper>
            <VkClients/>
          </VkClientsEventsContextWrapper>
      },
      {
        path: "/clients/create",
        element: <CreateClient/>
      },
      {
        path: "/clients/:id/edit",
        element: <EditClient/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }

          if (!checkSectionAction('clients',  '\\*', localStorage.getItem('companyId'), localStorage.getItem(`companies.${ localStorage.getItem('companyId') }.branch`)) && !checkCompanyCreate()) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/clients')
          }
          return null
        }
      },
      {
        path: "/reviews",
        element: <NpsReviewContextWrapper><Reviews/></NpsReviewContextWrapper>
      },
      {
        path: "/reviews/:id/edit",
        element: <EditReview/>
      },
      {
        path: "/nps",
        element: <Nps/>,
        loader: () => {
          if (auth &&
            auth.current &&
            auth.current.permissions &&
            auth.current.permissions.length === 0) {
            showErrorToast({
              content: "Нет доступа"
            })
            throw redirect('/')
          }
          return null
        }
      },
      {
        path: "/nps/create-question",
        element: <CreateQuestions/>
      },
      {
        path: "/nps/:id/edit-question",
        element: <EditQuestions/>
      },
      {
        path: "/food-rating",
        element:
          <ProductsContextWrapper>
            <CategoriesContextWrapper>
              <NpsGoodReviewContextWrapper>
                <FoodRating/>
              </NpsGoodReviewContextWrapper>
            </CategoriesContextWrapper>
          </ProductsContextWrapper>
      },
      {
        path: "/stocks",
        element: <Stocks/>
      },
      {
        path: "/stocks/create",
        element:
              <CreateStock/>
      },
      {
        path: "/stocks/:id/edit",
        element:
            <EditStock/>
      },
      {
        path: "/promo",
        element:
          <PromoContextWrapper>
          <PromoTagsContextWrapper>
            <Promo/>
          </PromoTagsContextWrapper>
        </PromoContextWrapper>
      },
      {
        path: "/promo/create",
        element: <CreatePromo/>
      },
      {
        path: "/promo/:id/edit",
        element: <EditPromo/>
      },
      {
        path: "/mailings",
        element:
          <MailingsContextWrapper>
            <MailingsArchiveContextWrapper>
              <Mailings/>
            </MailingsArchiveContextWrapper>
          </MailingsContextWrapper>
      },
      {
        path: "/mailings/create",
        element: <CreateMailing/>
      },
      {
        path: "/mailings/:id/edit",
        element: <EditMailing/>
      },
      {
        path: "/loyalty-programs",
        element: <LoyaltyPrograms/>
      },
      {
        path: "/loyalty-programs/create-rule",
        element: <CreateLoyaltyRule/>
      },
      {
        path: "/loyalty-programs/create-status",
        element: <CreateLoyaltyStatus/>
      },
      {
        path: "/loyalty-programs/status/:id/edit",
        element: <EditStatus/>
      },
      {
        path: "/loyalty-programs/rules/:id/edit",
        element: <EditRules/>
      },
      {
        path: "/loyalty-programs/create-transaction",
        element: <CreateLoyaltyTransaction/>
      },
      {
        path: "/tags",
        element: <Tags/>
      },
      {
        path: "/tags/create-orders-tag",
        element: <CreateOrdersTag/>
      },
      {
        path: "/tags/:id/edit-orders-tag",
        element: <EditOrdersTag/>
      },
      {
        path: "/tags/create-customers-tag",
        element: <CreateCustomersTag/>
      },
      {
        path: "/tags/:id/edit-customers-tag",
        element: <EditCustomersTag/>
      },
      {
        path: "/tags/create-promo-tag",
        element: <CreatePromoTag/>
      },
      {
        path: "/tags/:id/edit-promo-tag",
        element: <EditPromoTag/>
      },
      //TODO: выпилили метки компаний. Для их возвращения необходимо раскомменить тут и в выводе списка меток
      /*{
        path: "/tags/create-companies-tag",
        element: <CreateCompaniesTag/>
      },
      {
        path: "/tags/:id/edit-companies-tag",
        element: <EditCompaniesTag/>
      },*/
      {
        path: "/report-orders",
        element: <CustomersTagsContextWrapper>
          <OrdersTagsContextWrapper>
            <ReportOrdersContextWrapper>
            <ReportOrders/>
            </ReportOrdersContextWrapper>
          </OrdersTagsContextWrapper>
        </CustomersTagsContextWrapper>
      },
      {
        path: "/report-products",
        element: <ReportProductsContextWrapper>
          <ReportProducts/>
        </ReportProductsContextWrapper>,
      },
      {
        path: "/report-promo-codes",
        element: <ReportPromoCodes/>
      },
      {
        path: "/report-promo-code",
        element: <ReportPromoCode/>
      },
      {
        path: "/report-transaction",
        element: <ReportTransaction/>
      },
      {
        path: "/funnels",
        element: <FunnelsContextWrapper><Funnels/></FunnelsContextWrapper>
      },
      {
        path: "/funnels/create-group",
        element: <CreateFunnelGroup/>
      },
      {
        path: "/funnels/:id/edit-group",
        element: <EditFunnelGroup/>
      },
      {
        path: "/import/file",
        element: <ProductFileImport/>
      },
      {
        path: "/import/vk",
        element: <ProductVkImport/>
      },
      {
        path: "/import/frontpad",
        element: <FrontpadImport />
      },
      {
        path: "/import/partner",
        element: <PartnerImport />
      },
      {
        path: "/import/iiko",
        element: <IIKOImport />
      },
      {
        path: "/import/mobiDel",
        element: <MobiDelImport />
      },
      {
        path: "/import/sbisPresto",
        element: <SbisPrestoImport />
      },
      {
        path: "/import/rkeeper",
        element: <RKeeperImport />
      },
      {
        path: "/personal",
        element: <Personal />
      },
      {
        path: "*",
        element: <Navigate to='/companies'/>,
      }
    ]
  },
  {
    path: "/funnels/:id/constructor",
    element: <CreateFunnel/>
  },
]);

const routerNotAuth = createBrowserRouter([
  {
    path: "*",
    element: <Login/>
  },
  {
    path: 'vk-callback',
    element: <Login/>
  },
  {
    path: '/register',
    element: <Register/>,
  },
  {
    path: '/register',
    element: <Register/>,
  },
  {
    path: '/register/verify/email',
    element: <VerifyEmail></VerifyEmail>
  },
  {
    path: "/admin-auth/:token",
    element: <AdminAuth/>,
  },
]);

function App() {
  const {user, load} = useUser()

  useEffect(() => {
    //const interval = setInterval(() => {
      auth.update().then().catch()
    //}, 30000);

    //return () => clearInterval(interval);
  }, [])

  if (load) {
    return (
      <div className="App">
        Loading...
      </div>
    );
  } else {
    if (user) {
      return (
        <RouterProvider router={routerAuth}/>
      );
    } else {
      return (
        <div className="App">
          <RouterProvider router={routerNotAuth}/>
        </div>
      );
    }
  }
}

export default App;
