import React, {useState, useEffect} from 'react'
import TextInput from '../../../components/common/form/input/TextInput'
import Checkbox from './../../../components/common/form/checkbox/Checkbox';
import Textarea from './../../../components/common/form/input/Textarea';
import CheckboxList from '../../../components/common/form/checkbox/CheckboxList';
import TextInputMultipleList from '../../../components/common/form/input/TextInputMultipleList';
import SingleSimpleSelect from '../../../components/common/form/select/SingleSimpleSelect';
import TextInputsList from '../../../components/common/form/input/TextInputsList';
import ScheduleTime from '../../../components/common/form/ScheduleTime';

import fetchCurrencies from '../../../providers/dictionaries/currencies';
import fetchPhoneCodes from '../../../providers/dictionaries/phoneCodes';
import TextEditor from '../../../components/common/form/input/TextEditor';
import {useCompaniesContext} from "../../../contexts/Companies";


export default function Extended({data, updateField, errors, updateFieldNoRender}: any) {
  const {company} = useCompaniesContext()

  const [currencies, setCurrencies] = useState([])
  const [phoneCodes, setPhoneCodes] = useState([])
  const [originalVkCommunities, setOriginalVkCommunities] = useState(JSON.parse(JSON.stringify(data.extended?.vkCommunities || [])))

  useEffect(() => {
    fetchCurrencies().then(data => {
      setCurrencies(data)
    })

    fetchPhoneCodes().then(data => {
      setPhoneCodes(data)
    })
  }, [])

  useEffect(() => {
    // Это нужно чтобы установить выбранный элемент в селекторе, но в данном случае прилетает не только value, а весь объект так что просто делаем из него здесь подходящий под селектор
    if (data?.extended?.currency && !data?.extended?.__currency_select) {
      updateField('extended.__currency_select', {
        label: data.extended.currency.title,
        value: data.extended.currency.id
      })
    }

    if (data?.extended?.phoneCode && !data?.extended?.__phoneCode_select) {
      updateField('extended.__phoneCode_select', {
        label: data?.extended?.phoneCode.title,
        value: data?.extended?.phoneCode.id
      })
    }
  }, [data])

  const getVkCommunitiesDiff = (value: any) => {
    // 1. Новое сообщество              => отправляем полностью
    // 2. У сообщества поменялся ключ   => отправляем полностью
    // 3. Сообщество было удалено       => отправляем groupId и пустой ключ
    // 4. Ничего не поменялось          => не отправляем ничего
    // 5. Поменялся id                  => старое удаляем и создаем новое

    const diff: object[] = [];

    originalVkCommunities.forEach((vk: any) => {
      const row = value.find((row: any) => vk.groupId === row.groupId);

      // Удаление
      if (row === undefined) {
        diff.push({
          groupId: vk.groupId,
        });
        return;
      }

      // Ничего не поменялось
      if (vk.key === row.key) return;

      // Поменялся ключ 
      diff.push(row);
    });

    // Новые записи
    value.filter((row: any) => !originalVkCommunities.find((vk: any) => vk.groupId === row.groupId)).forEach((row: any) => {
      diff.push(row);
    });

    return diff;
  };

  return (
    <div className={'flex flex-col px-3 gap-y-6'}>
      {
        <>
          <div className={`flex flex-row gap-x-3`}>
            <TextInput
              label='Регион (Для геосервисов)'
              textTooltip='Регион необходимо указывать без типа субъекта, например: Чувашия, Пермский, Кировская.'
              placeholder='Сыктывкар, Киров, Кирово-Чепецк'
              name={'extended.regions'}
              errors={errors}
              className={'grow'}
              onChange={(value: any) => updateFieldNoRender('extended.regions', value.split(','))}
              value={data?.extended?.regions?.join(',')}
            />

            <TextInput
              label='Радиус'
              textTooltip='Указывается в метрах, максимальное значение 100км'
              placeholder=''
              type={'number'}
              className={'grow'}
              min={0}
              max={100000}
              name={'extended.dadataSuggestionsRadiusLimit'}
              errors={errors}
              onChange={(value: any) => updateFieldNoRender('extended.dadataSuggestionsRadiusLimit', value)}
              value={data.extended?.dadataSuggestionsRadiusLimit}
            />
          </div>

          <TextInputMultipleList
            name={'extended.orderCities'}
            values={data.extended.orderCities}
            fields={['code', 'city', 'title']}
            placeholders={['ID', 'Название', 'Полное название']}
            labels={['Код', 'Полное название', 'Город']}
            fieldTypes={['input', 'city', 'input']}
            textTooltips={['Код', 'Полное название', 'Город']}
            onChange={(value: any) => updateField('extended.orderCities', value)}
            label='Города (Для формы оформления заказов)'
            errors={errors}
          />


          <ScheduleTime
            textTooltip='Режим работы'
            name='extended.openHours'
            value={data.extended.openHours}
            onChange={(value: any) => updateField('extended.openHours', value)}
            errors={errors}
          />

          <Checkbox
            label={'Разрешить оформление заказов в нерабочее время'}
            name={'extended.allowOrderAfterHours'}
            onChange={(value: any) => updateField('extended.allowOrderAfterHours', value)}
            value={data.extended.allowOrderAfterHours}
            errors={errors}
          />

          <TextInputsList
            required={true}
            label='Адреса заведений'
            textTooltip='Адреса заведений'
            name='extended.addresses'
            values={data.extended.addresses}
            onChange={(value: any) => updateField('extended.addresses', value)}
            placeholder='Введите адрес заведения'
            errors={errors}
          />

          <TextInput
            label='Краткое описание'
            textTooltip='Краткое описание'
            className='w-full'
            placeholder='Введите краткое описание'
            onChange={(value: any) => updateFieldNoRender('extended.shortDescription', value)}
            value={data.extended.shortDescription}
            name={'extended.shortDescription'}
            errors={errors}
          />

          <TextEditor
            label="Подробное описание"
            textTooltip="Введите подробное описание"
            placeholder='Подробное описание'
            value={data.extended.description}
            onChange={(value: any) => updateFieldNoRender('extended.description', value.trim().length !== 0 ? value : '')}
          />

          <TextEditor
            label='Оплата'
            textTooltip='Оплата'
            placeholder='Оплата'
            onChange={(value: any) => updateFieldNoRender('extended.paymentDescription', value.trim().length !== 0 ? value : '')}
            value={data.extended.paymentDescription}
          />

          <TextEditor
            label='Доставка'
            textTooltip='Доставка'
            placeholder='Доставка'
            onChange={value => updateFieldNoRender('extended.deliveryDescription', value.trim().length !== 0 ? value : '')}
            value={data.extended.deliveryDescription}
          />

          <Textarea
            label='Акции'
            textTooltip='Акции'
            placeholder='Акции'
            name={'extended.stockDescription'}
            errors={errors}
            onChange={value => updateFieldNoRender('extended.stockDescription', value.trim().length !== 0 ? value : '')}
            value={data.extended.stockDescription}
            maxLength={undefined}
          />

          {/* <TextInput
              label='Программа лояльности'
              className='w-full'
              placeholder='Программа лояльности'
              onChange={value => updateFieldNoRender('extended.loyaltyProgramDescription', value)}
              value={data.extended.loyaltyProgramDescription}
              name={'extended.loyaltyProgramDescription'}
              errors={errors}
            /> */}

          <TextInput
            label='Текст на странице “Корзина”'
            textTooltip='Текст на странице “Корзина”'
            className='w-full'
            placeholder='Введите текст на странице “Корзина”'
            onChange={(value: any) => updateFieldNoRender('extended.cartText', value)}
            value={data.extended.cartText}
            name={'extended.cartText'}
            errors={errors}
          />

          <TextInput
            label='Текст на странице “Оформление заказа”'
            textTooltip='Текст на странице “Оформление заказа”'
            className='w-full'
            placeholder='Введите текст на странице “Оформление заказа”'
            onChange={(value: any) => updateFieldNoRender('extended.orderPageText', value)}
            value={data.extended.orderPageText}
            name={'extended.orderPageText'}
            errors={errors}
          />

          <TextInput
            label='Текст в подвале'
            textTooltip='Текст в подвале'
            className='w-full'
            placeholder='Введите текст в подвале'
            onChange={(value: any) => updateFieldNoRender('extended.footerText', value)}
            value={data.extended.footerText}
            name={'extended.footerText'}
            errors={errors}
          />

          <div className='flex gap-4'>
            <SingleSimpleSelect
              className={'grow'}
              classNameInput=''
              label='Валюта'
              textTooltip='Валюта'
              placeholder='Рубль'
              name={'base.currency'}
              errors={errors}
              options={currencies}
              onChange={(option: any) => {
                updateField('extended.__currency_select', option)
                updateField('extended.currency', option.value)
              }}
              value={data?.extended?.__currency_select}
            />

            <SingleSimpleSelect
              className={'grow'}
              classNameInput=''
              label='Код телефонов'
              textTooltip='Код телефонов'
              placeholder='+7'
              name={'base.phoneCode'}
              errors={errors}
              options={phoneCodes}
              onChange={(option: any) => {
                updateField('extended.__phoneCode_select', option)
                updateField('extended.phoneCode', option.value)
              }}
              value={data?.extended?.__phoneCode_select}
            />
          </div>

          {/*<TextInputsList
              inputType='number'
              label='Сообщество ВКонтакте'
              textTooltip='Сообщество ВКонтакте'
              className='w-full'
              placeholder='Введите ID сообщество ВКонтакте'
              onChange={(value: any) => updateFieldNoRender('extended.vkCommunities', value)}
              values={data.extended.vkCommunities || []}
              name={'vkCommunities'}
              errors={errors}
              vk={true}
              vkId={company.vkAppId}
            />*/}

          <TextInputMultipleList
            name={'extended.vkCommunities'}
            values={data.extended?.vkCommunities || []}
            fields={['groupId', 'key']}
            placeholders={['ID', 'Ключ']}
            labels={['ID', 'Ключ']}
            fieldTypes={['input', 'token-input']}
            textTooltips={['ID', 'Ключ']}
            onChange={(value: any) => {
              updateField('extended.vkCommunities', value);
              updateFieldNoRender('extended.vkCommunitiesDiff', getVkCommunitiesDiff(value));
            }}
            onFieldChange={[
              (value: any, index: any, field: any) => {
                // On groupId field change
                if (data.extended?.vkCommunities
                  && data.extended.vkCommunities[index]
                  && index < originalVkCommunities.length
                  && data.extended.vkCommunities[index].key === originalVkCommunities[index].key) {
                  updateField(`extended.vkCommunities.${index}.key`, '');
                }
              },
              (value: any, index: any, field: any) => {
                // On key field change
              }
            ]}
            label='Сообщество ВКонтакте'
            errors={errors}
            vk={true}
            vkId={company?.vkAppId}
            className='w-full'
          />

          {/*<Checkbox
              label={'Разрешить привязку / изменение телефона к авторизированному пользователю'}
              name={'extended.allowAssignPhone'}
              onChange={(value: any) => updateField('extended.allowAssignPhone', value)}
              value={data.extended.allowAssignPhone}
              errors={errors}
            />*/}

          <Checkbox
            label={'Разрешить оформление заказа без подтверждения номера телефона'}
            name={'extended.allowGuestOrderInMobile'}
            onChange={(value: any) => updateField('extended.allowGuestOrderInMobile', value)}
            value={data.extended.allowGuestOrderInMobile}
            errors={errors}
          />

          <CheckboxList
            label='Варианты входа:'
            name='extended.allowedAuthorizeTypes'
            errors={errors}
            options={[
              {
                label: 'Телефон + СМС-код',
                name: 'sms',
              },
              {
                label: 'Телефон + звонок',
                name: 'call',
              },
              {
                label: 'ВКонтакте',
                name: 'vk',
              },
              {
                label: 'Telegram (бот)',
                name: 'tg',
              },
            ]}
            values={data.extended.allowedAuthorizeTypes}
            onChange={(value: any) => updateField('extended.allowedAuthorizeTypes', value)}
          />

          {
            data.extended.allowedAuthorizeTypes.includes('tg') &&
              <div className={`py-3 px-4 border-gray-20 border-[1px] rounded-lg`}>
                  <TextInput
                      textTooltip='Токен Telegram Bot'
                      label='Токен Telegram Bot'
                      placeholder='Токен Telegram Bot'
                      name={'extended.tgBotToken'}
                      errors={errors}
                      onChange={(value: any) => updateFieldNoRender('extended.tgBotToken', value)}
                      value={data.extended.tgBotToken}
                      initialValueReplaceOnly={true}
                  />
              </div>
          }

          <TextInput
            label='ID филиала в яндекс для отзывов'
            textTooltip='ID филиала в яндекс для отзывов'
            placeholder='Введите ID'
            name={'extended.yandexId'}
            errors={errors}
            onChange={(value: any) => updateField('extended.yandexId', value)}
            value={data.extended.yandexId}
            required={false}
          />

          <Textarea
            label='Код вставки виджета 2gis'
            placeholder='Введите код вставки виджета 2gis'
            name={'extended.twoGisCode'}
            errors={errors}
            onChange={(value) => {
              updateField('extended.twoGisCode', value)
            }}
            value={data.extended.twoGisCode || ''}
            textTooltip={'Код вставки виджета 2gis'}
          />
        </>
      }
    </div>
  )
}
