import branchImg from '../../../images/branch.svg'
import catalogImg from '../../../images/catalog.svg'
import ordersImg from '../../../images/orders.svg'
import clientsImg from '../../../images/clients.svg'
import npsImg from '../../../images/NPS.svg'
import stockImg from '../../../images/stock.svg'
import historyImg from '../../../images/history.svg'
import promosImg from '../../../images/promos.svg'
import funnelImg from '../../../images/funnel.svg'
import mailingsImg from '../../../images/mailings.svg'
import tagImg from '../../../images/tag.svg'
import employeesImg from '../../../images/employees.svg'
import reportsImg from '../../../images/reports.svg'
import paymentImg from '../../../images/payment.svg'
import loyaltyProgramImg from '../../../images/loyaltyProgram.svg'

const menuItems = [
  {
    icon: branchImg,
    label: 'Филиалы',
    name: 'branches',
    items: [
      {
        label: 'Все филиалы',
        url: '/branches'
      },
      {
        label: 'Зоны доставки',
        url: '/delivery-zones'
      }
    ]
  },
  {
    icon: catalogImg,
    label: 'Каталог',
    name: 'catalog',
    items: [
      {
        label: 'Разделы',
        url: '/categories'
      },
      {
        label: 'Товары',
        url: '/products'
      },
      {
        label: 'Импорт',
        //url: '/import',
        name: 'import',
        items: [
          {
            label: 'Из файла',
            url: '/import/file'
          },
          {
            label: 'Из Вконтакте',
            url: '/import/vk'
          },
          {
            label: 'Из CRM - Frontpad',
            integration: 'frontpad',
            url: '/import/frontpad'
          },
          {
            label: 'Из CRM - Партнер',
            integration: 'partner',
            url: '/import/partner'
          },
          {
           label: 'Из CRM - IIKO',
            integration: 'iiko',
           url: '/import/iiko'
          },
          {
            label: 'Из CRM - MobiDel',
            integration: 'mobiDel',
            url: '/import/mobiDel'
          },
          {
            label: 'Из CRM - СБИС Presto',
            integration: 'sbisPresto',
            url: '/import/sbisPresto'
          },
          {
            label: 'Из CRM - R_Keeper',
            integration: 'rkeeper',
            url: '/import/rkeeper'
          }
        ]
      },
      {
        label: 'Опции',
        url: '/options'
      },
    ]
  },
  {
    icon: ordersImg,
    label: 'Заказы',
    name: 'orders',
    url: '/orders'
  },
  {
    icon: clientsImg,
    label: 'Клиенты',
    name: 'clients',
    items: [
      {
        label: 'Все клиенты',
        url: '/clients'
      },
      {
        label: 'Посетители ВК',
        url: '/vk-clients'
      }
    ]
  },
  {
    icon: npsImg,
    label: 'NPS',
    name: 'consumer-loyalty-index',
    items: [
      {
        label: 'Отзывы',
        url: '/reviews'
      },
      {
        label: 'Оценка блюд',
        url: '/food-rating'
      },
      {
        label: 'NPS',
        url: '/nps'
      },
    ]
  },
  {
    icon: stockImg,
    label: 'Акции',
    name: 'offers',
    url: '/stocks'
  },
  {
    icon: historyImg,
    label: 'Истории',
    name: 'histories',
    url: '/histories'
  },
  {
    icon: promosImg,
    label: 'Промокоды',
    name: 'offers',
    url: '/promo'
  },
  {
    icon: funnelImg,
    label: 'Воронки',
    name: 'events',
    url: '/funnels'
  },
  {
    icon: mailingsImg,
    label: 'Рассылки',
    name: 'distributions',
    url: '/mailings'
  },
  {
    icon: loyaltyProgramImg,
    label: 'Программа лояльности',
    name: 'loyalty-program',
    url: '/loyalty-programs',
  },
  {
    icon: tagImg,
    label: 'Метки',
    name: 'labels',
    url: '/tags',
  },
  {
    icon: employeesImg,
    label: 'Сотрудники',
    name: 'employees',
    url: '/employees'
  },
  {
    icon: reportsImg,
    label: 'Отчеты',
    name: 'reports',
    items: [
      {
        label: 'Заказы',
        url: '/report-orders'
      },
      {
        label: 'Товары',
        url: '/report-products'
      },
      {
        label: 'Промокоды',
        url: '/report-promo-codes'
      },
      {
        label: 'Промокод',
        url: '/report-promo-code'
      },
      //{
      //  label: 'Транзакции',
      //  url: '/report-transaction'
      //},
    ]
  },
  {
    icon: paymentImg,
    label: 'Тарифы и оплата',
    name: 'payment',
    url: ''
  },
]

export default menuItems
